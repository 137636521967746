@import-normalize; /* bring in normalize.css styles */
@import url('https://fonts.googleapis.com/css?family=Prompt:400,500,600');

* {
  font-family: Prompt;
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
}

.fit {
  max-width: 100%;
}

//#region global class for Swal image
.image-fit-cover {
  object-fit: cover;
  object-position: center;
}

.image-fit-contain {
  object-fit: contain;
  object-position: center;
}
//#endregion global class for Swal image

.filter-light-blue {
  filter: invert(34%) sepia(63%) saturate(7313%) hue-rotate(243deg) brightness(108%) contrast(101%);
}

.campaignReportExportDropdown {
  cursor: pointer;
}

.campaignReportExportDropdown:hover {
  color: #664aff;
}

.swal-custom {
  .swal2-content {
    padding-top: 24px;
  }

  .swal2-actions {
    justify-content: flex-end;
  }
  .swal2-close {
    font-size: 24px;
    margin: 8px;
  }

  .swal2-cancel {
    color: #2d3748 !important;
  }
}
